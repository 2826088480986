<template>
  <div class="version">
    <div class="banner">
        <h1>{{ productName }}</h1>
    </div>
    <div class="t-content">
        <!-- <div class="big-label">{{ productName }}</div> -->
        <!-- <div class="t-navbar">
            <div class="t-navbar-item">
                Firmware Download
            </div>
        </div> -->
        <table>
            <thead>
                <tr>
                    <th style="width: 75%;">Version and Patch Number</th>
                    <th style="width: 12%;">Status</th>
                    <th style="width: 12%;">Publication Date</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in list" :key="index">
                    <td><span class="t-link" @click="toPage(item.versionCode)">{{ item.versionNum }}</span></td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.publicationDate }}</td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</template>

<script>
// import {mapState} from 'vuex'
import {findVisionByPId} from '@/api/firmwareApi'
export default {
    name: 'FirmwareVersion',
    data() {
        return {
            id: null,
            list: [],
            productName: ''
        }
    },
    computed: {

    },
    mounted() {
        // console.log(this.$store)
        this.id = this.$route.params.id;
        this.productName = this.$store.state.firmware.firmProduct[this.id]
        this.getData();
    },
    methods: {
        async getData() {
            if (!this.id) {
                return false;
            }
           const {data: {data, isSuccess}} = await findVisionByPId(this.id)
           if (isSuccess) {
            let key = Object.keys(data)[0];
            this.productName = key;
            this.list = data[key];
           }
        },
        toPage(versionCode) {
            const url = this.$router.resolve({
                path: `/firmware-soft/${versionCode}`
            })
            window.open(url.href,'_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
.version {
    
    .t-content {
        width: 70%;
        margin: auto;
    }
    .banner {
        height: 320px;
        background-image: url('/static/img/banner.8e08f679.jpg');
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.29);
            pointer-events: none;
        }
        h1 {
            text-align: left;
            width: 70%;
            margin: auto;
            // padding-left: 200px;
            font-weight: 600;
            color: #ffffff;
            z-index: 2;
        }
    }
    .big-label {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        padding-left: 10px;
    }
    .t-navbar {
        margin-top: 10px;
        height: 36px;
        // background-color: #404040;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .t-navbar-item {
            height: 100%;
            padding: 0 10px;
            // font-size: 18px;
            background-color: #c7000b;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    table {
        width: 100%;
        margin-top: 8px;
        thead {
            height: 36px;
            background-color: #f0f0f0;
            th {
                padding: 0 10px;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid rgba(128, 128, 128, 0.219);
                height: 36px;
                td {
                    padding: 0 10px;
                }
                .t-link {
                    border-bottom: 1px solid transparent;
                    cursor: pointer;
                    &:hover {
                        color: #c7000b;
                        border-bottom: 1px solid #c7000b;
                    }
                }
            }
        }
    }
}
</style>